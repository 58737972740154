body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus-visible {
  outline-style: ridge;
  outline-width: 2px;
  outline-color: #afcbff;
  outline-offset: 1px;
}

:root {
  --app-brown: rgb(35, 12, 15);
  --app-red: rgb(252, 47, 0);
  --app-green: rgb(68, 221, 25);
}
